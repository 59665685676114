<template>
    <div class="wrapper-listado noticias">

        <div class="header-grid row-between">

            <h2>Eventos</h2>

            <div class="buscador row-end">

                <input type="text" name="carrera" placeholder="Buscar" autocomplete="nope" v-model="search"
                    @keyup.enter="setData">

                <button @click="setData" class="boton-azul"><i class="fa fa-search"></i></button>

            </div>

        </div>


        <div class="contenedor-listados">
            <section class="listado-grid row-start fww aifs" v-if="data.length != 0">

                <article :key="key" v-for="(el,key) in data"
                    :style="'background:#444 url('+el.image.url+') center/cover no-repeat;'" class="small">
                    <router-link :to="'/es/comunidad/eventos/'+el.slug" class="enlace-cover"></router-link>
                    <!-- <h2 class="pasado" v-if="ispast(el.date)">Evento<br>Pasado</h2> -->
                    <div class="contenedor-texto">
                        <h2>{{el.title}}</h2>
                        <h3 class="carrera">{{el.date | date}}</h3>
                        <h4 class="titular">{{el.header}}</h4>
                    </div>
                </article>

            </section>

            <section class="listado-grid" v-else>
                <p>No hay resultados de la búsqueda</p>
            </section>


        </div>


        <nav class="paginador" v-if="pages > 1">
            <a v-if="page > 1" @click="prev" class="prev" data="prev"><i class="fa fa-caret-down"
                    aria-hidden="true"></i></a>
                    <template v-for="(n,key) in pages">
                        <a @click="setPage(n)" data="1" :class="(n == page) ? 'activo' : ''" :key="key"
                         v-if="n == (page-1) || n == (page+1) || page == n">{{n}}</a>
                    </template>
            <a v-if="page < pages" @click="next" class="next" data="next"><i class="fa fa-caret-down"
                    aria-hidden="true"></i></a>
        </nav>


    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    export default {
        name: 'noticias',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: '',
            perpage: 16,
            page: 1,
            total: 0,
            search: ''
        }),
        methods: {
            ...mapActions(['requestAllEventos']),
            setData() {

                let data = {
                    length: this.perpage,
                    page: this.page,
                    filters: [],
                    order: [
                        {
                            name: 'date',
                            direction: 'desc'
                        }
                    ]
                };

                if (this.search) {

                    let searchIn = ['title'];

                    searchIn.forEach(id => {

                        let el = {
                            filterPack: true,
                            filters: [
                                {
                                    "name": id,
                                    "search": this.search,
                                    "textFilter": 1
                                }
                            ]
                        }

                        data.filters.push(el);

                    });

                }

                this.requestAllEventos(data).then(r => {
                    this.data = r.data;
                    this.total = r.recordsFiltered;
                });
            },
            next() {
                if (this.page < this.pages) {
                    this.page++;
                    this.setData();
                }
            },
            prev() {
                if (this.page > 1) {
                    this.page--;
                    this.setData();
                }
            },
            setPage(n) {
                this.page = n;
                this.setData();
            },
            ispast(date) {
                let event = new Date(date).getTime();
                let actual = new Date().getTime();

                return (event < actual);
            }
        },
        computed: {
            pages() {
                return Math.ceil(this.total / this.perpage);
            }
        }
    }

</script>